<template>
<div class="row">
  <div class="col-md-3 col-xs-12" v-for="card in cards" :key="card.recurringDetailReference">
    <card :card="card" @tryDelete="showModalConfirm"/>
  </div>
</div>
</template>

<script>
import Card from '@/components/CreditCards/Card'

export default {
  data () {
    return {
      modalShowConfirm: false,
      modalShowDeleted: false
    }
  },
  props: {
    cards: {
      type: Array
    }
  },
  methods: {
    updateModalConfirm (value) {
      this.modalShowConfirm = value
    },
    confirmDelete (obj) {
      this.modalShowConfirm = false
    },
    showModalConfirm (cardOpt) {
      this.$emit('tryDelete', cardOpt)
    }
  },
  components: {
    Card
  }
}
</script>
