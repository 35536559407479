<template>
  <div>
    <b-card class="bcm-card">
      <b-card-text>{{ $t('number') }} : {{ card.number }}</b-card-text>
      <b-card-text>{{ $t('expire_date') }} : {{ expireDate }}</b-card-text>
      <b-card-text>{{ $t('type') }} : <img :src="getURLimg" alt="" class="card-img"> </b-card-text>
      <div class="d-flex justify-content-end">
        <button @click="showModalConfirm(card)" class='card-link btn btn-none'>{{ $t('Delete') }}</button>
      </div>
    </b-card>
  </div>
</template>

<script>
import config from '@/config'

export default {
  props: {
    card: {
      type: Object
    }
  },
  computed: {
    expireDate () {
      return this.$t('adyenExpiration', this.card)
    },
    getURLimg () {
      return config.API.payments.adyen.imgPath.replace('{{type}}', this.card.type)
    }
  },
  methods: {
    showModalConfirm () {
      this.$emit('tryDelete', this.card)
    }
  }
}
</script>
<style lang="scss" scoped>
.ff-card{
    border-radius: 20px;
}
.card-img{
    width: 40px;
    height: 25px;
}
</style>
